import {
  BuildingLibraryIcon,
  ChatBubbleLeftRightIcon,
  GlobeEuropeAfricaIcon,
  PresentationChartLineIcon,
  ScaleIcon,
  Square3Stack3DIcon
} from '@heroicons/react/24/outline';
import { OpenInNew } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import CFactsheetDisclaimer from '../../components/CFactsheetDisclaimer';
import CLocalizedText from '../../components/CLocalizedText';
import CPageModule from '../../components/CPageModule';
import CPageModulePlot from '../../components/CPageModulePlot';
import CPandasTable from '../../components/CPandasTable';
import CResponsiveGrid from '../../components/CResponsiveGrid';
import CStatefulContainer from '../../components/CStatefulContainer';
import CTextFieldDisplay from '../../components/CTextFieldDisplay';
import ActionLinkButton from '../../components/dashboardContent/ActionLinkButton';
import Page from '../../components/layout/Page';
import { BreakpointsInPx } from '../../enums/BreakpointsInPx';
import { PageRoutes } from '../../enums/enums';
import { updateState, useStateExtended } from '../../helpers/helper';
import { useAppSelector } from '../../hooks/hooks';
import { type IRequestState } from '../../models/IRequestState';
import { type PlotlyResponse } from '../../models/PlotlyModel';
import { type TPandasTable } from '../../models/TPandasTable';
import PublicFactsheetServices from '../../services/publicFactsheetServices';
import CAnteilsklasseTab from './CAnteilsklasseTab';

interface State {
  states?: Partial<{ overview: IRequestState; overviewTable: IRequestState }>;
  response?: Partial<{ overview: PlotlyResponse; overviewTable: TPandasTable }>;
}

export default function PFactsheetIndex() {
  const { locale, dashboardOutletDimensions } = useAppSelector((state) => state.dashboard);
  const { factsheetId } = useParams();
  const [state, setState, getState] = useStateExtended<State>({
    states: {
      overview: { isLoading: true, isError: false },
      overviewTable: { isLoading: true, isError: false }
    }
  });

  const fetchCharts = async () => {
    updateState<State>(
      {
        states: {
          overview: { ...(await getState()).states, isLoading: true, isError: state.states?.overview?.isError ?? false }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getFactsheetChart(factsheetId ?? '', locale);
    updateState<State>(
      {
        states: {
          overview: {
            ...(await getState()).states,
            isLoading: false,
            isError: res.hasError(),
            strError: res.getErrorString()
          }
        }
      },
      state,
      setState
    );
    if (res.wasSuccessful()) {
      updateState<State>({ response: { ...(await getState()).response, overview: res.getData() } }, state, setState);
    }
  };

  const fetchTable = async () => {
    updateState<State>(
      {
        states: {
          ...(await getState()).states,
          overviewTable: { isLoading: true, isError: state.states?.overviewTable?.isError ?? false }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getFactsheetOverviewTable(factsheetId ?? '', locale);
    updateState<State>(
      {
        states: {
          ...(await getState()).states,
          overviewTable: { isLoading: false, isError: res.hasError(), strError: res.getErrorString() }
        }
      },
      state,
      setState
    );
    if (res.wasSuccessful()) {
      updateState<State>(
        { response: { ...(await getState()).response, overviewTable: res.getData() } },
        state,
        setState
      );
    }
  };

  useEffect(() => {
    (async () => {
      await fetchTable();
      await fetchCharts();
    })();
  }, [locale]);

  return (
    <Page>
      <CPageModule>
        <CLocalizedText dictKey={'fsovFirm'} />
      </CPageModule>
      <CPageModule fullWidth style={{ paddingTop: 0 }}>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fsovTitle'} />
          </h3>
          <CLocalizedText dictKey={'fsovDesc'} />
        </CPageModule>
        <CPageModulePlot
          data={state.response?.overview?.data}
          layout={state.response?.overview?.layout}
          apiRequestState={state.states?.overview}
          showWhen={!!state.response?.overview}
          aspectRatio={2}
        />
        <CPageModule submodule>
          <CResponsiveGrid maxColumns={2} style={{ columnGap: '1.5rem', rowGap: '1.5rem' }}>
            <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
              <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovAllocH1'} />}>
                <CLocalizedText dictKey={'fsovAllocB1'} />
              </CTextFieldDisplay>
              <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovAllocH2'} />}>
                <CLocalizedText dictKey={'fsovAllocB2'} />
              </CTextFieldDisplay>
              <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovAllocH3'} />}>
                <CLocalizedText dictKey={'fsovAllocB3'} />
              </CTextFieldDisplay>
            </CPageModule>
            <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
              <CStatefulContainer
                apiRequestState={state.states?.overviewTable}
                showContent={!!state.response?.overviewTable}
              >
                <CPandasTable
                  data={state.response?.overviewTable}
                  style={{ minWidth: 'auto', width: '100%' }}
                  colSpanArr={[1, 1, 1]}
                  colAlignmentArr={[undefined, 'right']}
                  showIndex
                />
              </CStatefulContainer>
            </CPageModule>
          </CResponsiveGrid>
        </CPageModule>
      </CPageModule>
      <CPageModule style={{ paddingTop: 0 }}>
        <h3>
          <CLocalizedText dictKey={'fsovP2H1'} />
        </h3>
        <CResponsiveGrid maxColumns={3}>
          <ActionLinkButton
            label={<CLocalizedText dictKey={'fsovP2Performance'} />}
            to={PageRoutes.PUBLIC_FACTSHEET_PERFORMANCE}
            showNavigationHint={'go'}
          >
            <PresentationChartLineIcon />
          </ActionLinkButton>
          <ActionLinkButton
            label={<CLocalizedText dictKey={'globalFactsheetSectionFacex'} />}
            to={PageRoutes.PUBLIC_FACTSHEET_FACTOR_EXPOSURE}
            showNavigationHint={'go'}
          >
            <Square3Stack3DIcon />
          </ActionLinkButton>
          <ActionLinkButton
            label={<CLocalizedText dictKey={'globalFactsheetSectionSecex'} />}
            to={PageRoutes.PUBLIC_FACTSHEET_SECTOR_EXPOSURE}
            showNavigationHint={'go'}
          >
            <BuildingLibraryIcon />
          </ActionLinkButton>
          <ActionLinkButton
            label={<CLocalizedText dictKey={'fsovP2Attribution'} />}
            to={PageRoutes.PUBLIC_FACTSHEET_PERFORMANCE_ATTRIBUTION}
            showNavigationHint={'go'}
          >
            <ChatBubbleLeftRightIcon />
          </ActionLinkButton>
          <ActionLinkButton
            label={<CLocalizedText dictKey={'globalFactsheetSectionDelta'} />}
            to={PageRoutes.PUBLIC_FACTSHEET_DELTA}
            showNavigationHint={'go'}
          >
            <ScaleIcon />
          </ActionLinkButton>
          <ActionLinkButton
            label={<CLocalizedText dictKey={'globalFactsheetSectionEsg'} />}
            to={PageRoutes.PUBLIC_FACTSHEET_ESG}
            showNavigationHint={'go'}
          >
            <GlobeEuropeAfricaIcon />
          </ActionLinkButton>
        </CResponsiveGrid>
      </CPageModule>
      <CPageModule fullWidth style={{ paddingTop: 0 }}>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fsovP3H1'} />
          </h3>
        </CPageModule>
        <CAnteilsklasseTab />
      </CPageModule>
      <CPageModule style={{ paddingTop: 0 }}>
        <CResponsiveGrid maxColumns={3} style={{ rowGap: '1.5rem', columnGap: '1.5rem' }}>
          <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
            <h3>
              <CLocalizedText dictKey={'fsovP8H1'} />
            </h3>
            <div>
              <CLocalizedText dictKey={'fsovP8Desc'} />
              <Link to={'https://amfileon.com/'} target={'_blank'}>
                https://amfileon.com <OpenInNew />
              </Link>
            </div>
          </CPageModule>
          <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
            <h3>
              <CLocalizedText dictKey={'fsovP6H1'} />
            </h3>
            <div>
              <CLocalizedText dictKey={'fsovP6Desc'} />
              <Link to={'https://www.bnpparibas.com'} target={'_blank'}>
                https://www.bnpparibas.com <OpenInNew />
              </Link>
            </div>
          </CPageModule>
          <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
            <h3>
              <CLocalizedText dictKey={'fsovP7H1'} />
            </h3>
            <div>
              <CLocalizedText dictKey={'fsovP7Desc'} />
              <Link to={'https://www.universal-investment.com/'} target={'_blank'}>
                https://www.universal-investment.com <OpenInNew />
              </Link>
            </div>
          </CPageModule>
        </CResponsiveGrid>
      </CPageModule>
      <CPageModule>
        <CResponsiveGrid maxColumns={3} style={{ rowGap: '1.5rem', columnGap: '1.5rem' }}>
          <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
            <h3>
              <CLocalizedText dictKey={'fsovP4H1'} />
            </h3>
            <CLocalizedText dictKey={'fsovP4Desc'} />
          </CPageModule>
          <div
              style={{
                gridColumn:
                    dashboardOutletDimensions.w < BreakpointsInPx.BREAKPOINT_3 ? 'span 1 / span 1' : 'span 2 / span 2'
              }}
          >
            <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
              <h3>
                <CLocalizedText dictKey={'fsovP5H1'} />
              </h3>
              <CLocalizedText dictKey={'fsovP5Desc'} />
            </CPageModule>
          </div>
        </CResponsiveGrid>
      </CPageModule>
      <CFactsheetDisclaimer />
    </Page>
  );
}
